import { DEFAULT_LOCALE } from '@constants/index';
import { useOutsideTranslation } from '@utils/hooks/useOutsideTranslation';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import dynamic from 'next/dynamic';

const NotFoundError = dynamic(() => import('@components/errors/NotFoundError'), { ssr: false });

export const getStaticProps = (async ({ locale, defaultLocale }) => {
  return {
    props: {
      locale: locale || defaultLocale || DEFAULT_LOCALE
    },
    revalidate: 3600
  };
}) satisfies GetStaticProps;

const PageNotFound = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  useOutsideTranslation(props.locale);
  return <NotFoundError />;
};

export default PageNotFound;
