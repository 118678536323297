import { useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
export const useOutsideTranslation = (locale?: string) => {
  useEffect(() => {
    const newInstance = i18next.createInstance();
    newInstance
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init(
        {
          lng: locale || 'es',
          supportedLngs: ['es', 'en', 'pt'],
          fallbackLng: {
            es: ['es'],
            en: ['en'],
            pt: ['pt'],
            default: ['es']
          },
          ns: ['common', 'menu', 'thank'],
          react: {
            useSuspense: false
          }
        },
        (err) => {
          if (err) {
            return console.log('something went wrong loading', err);
          }
        }
      );
  }, [locale]);
};
